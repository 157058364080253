import User from 'api/user'
import axios from 'axios'
import { setup } from 'lib/adapter'

import constants from '../constants/user'

export const fetchUser = (id) => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_USER_REQUEST
  })

  try {
    if (typeof window !== 'object') {
      setup(getState().currentUser.adapter)
    }

    const user = await User.fetch(id)

    dispatch({
      type: constants.FETCH_USER_SUCCESS,
      user
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_USER_FAILURE,
      errorMessage: response.statusText || response.status,
      errors: []
    })
  }
}

export const verifyUser = (credentials) => async (dispatch, getState) => {
  dispatch({
    type: constants.VERIFY_USER_REQUEST
  })

  const token = getState().currentUser.adapter.token

  try {
    const response = await axios.post(
      `/api/v2/users/verify`,
      {
        data: {
          attributes: {
            email_or_login: credentials.emailOrLogin,
            password: credentials.password,
            remember_me: credentials.rememberMe
          }
        }
      },
      {
        headers: {
          'x-api-token': `Bearer ${token}`
        }
      }
    )

    dispatch({
      type: constants.VERIFY_USER_SUCCESS,
      user: response.data
    })

    return response.data
  } catch (error) {
    dispatch({
      type: constants.VERIFY_USER_FAILURE,
      errorMessage: true,
      errors: error
    })
  }
}

export const verifyAdmin = (credentials) => async (dispatch, getState) => {
  dispatch({
    type: constants.VERIFY_USER_REQUEST
  })

  try {
    const user = new User({
      ...getState().currentUser.adapter,
      ...credentials
    })

    const response = await user.verifyAdmin()
    dispatch({
      type: constants.VERIFY_USER_SUCCESS,
      user: response,
      message: response.status
    })
  } catch (error) {
    dispatch({
      type: constants.VERIFY_USER_FAILURE,
      errorMessage: error.status
    })
  }
}

export const resetUserPassword = (credentials) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: constants.FETCH_USER_REQUEST
  })

  try {
    const user = new User({
      ...getState().currentUser.adapter,
      ...credentials
    })

    await user.resetPassword()
    dispatch({
      type: constants.RESET_USER_PASSWORD_SUCCESS,
      user
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_USER_FAILURE,
      errorMessage: true,
      errors: response.data.errors
    })
  }
}

export const forgotUserPassword = (credentials) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: constants.FORGOT_USER_PASSWORD_REQUEST
  })

  try {
    const user = new User({
      ...getState().currentUser.adapter,
      ...credentials
    })

    await user.forgotPassword()
    dispatch({
      type: constants.FORGOT_USER_PASSWORD_SUCCESS
    })
  } catch (error) {
    dispatch({
      type: constants.FORGOT_USER_PASSWORD_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const resendConfirmEmail = (credentials) => async (
  dispatch,
  getState
) => {
  dispatch({
    type: constants.RESEND_CONFRIM_EMAIL_REQUEST
  })

  try {
    const user = new User({
      ...getState().currentUser.adapter,
      ...credentials
    })

    const response = await user.resendConfirmEmail()
    dispatch({
      type: constants.RESEND_CONFRIM_EMAIL_SUCCESS,
      user: response
    })
  } catch (error) {
    dispatch({
      type: constants.RESEND_CONFRIM_EMAIL_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const verifyAccount = (credentials) => async (dispatch, getState) => {
  dispatch({
    type: constants.VERIFY_OTP_REQUEST
  })

  try {
    const user = new User({
      ...getState().currentUser.adapter,
      ...credentials
    })

    const response = await user.verifyAccount()
    dispatch({
      type: constants.VERIFY_OTP_SUCCESS,
      user: response
    })
  } catch (error) {
    dispatch({
      type: constants.VERIFY_OTP_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const resendEmail = (credentials) => async (dispatch, getState) => {
  dispatch({
    type: constants.RESEND_CONFRIM_EMAIL_REQUEST
  })

  try {
    const user = new User({
      ...getState().currentUser.adapter,
      ...credentials
    })

    const response = await user.resendEmail()
    dispatch({
      type: constants.RESEND_CONFRIM_EMAIL_SUCCESS,
      user: response
    })
  } catch (error) {
    dispatch({
      type: constants.RESEND_CONFRIM_EMAIL_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const updatePreferredSiteLocale = (locale) => async (dispatch, getState) => {
  const patchedUser = new User({
    ...getState().currentUser.adapter,
    adminLocale: locale
  })

  return patchedUser.updateAdminLocale()
}

export const setAdapter = (adapter) => {
  return {
    type: constants.SET_ADAPTER,
    adapter
  }
}
