import Model from '@itsfadnis/jsonapi-client'

class Libraries extends Model {
  static baseURL = '/intercom';

  constructor (args = {}) {
    super(args)
    Model.call(this, args)
    this.email = args.email
    this.name = args.name
    this.new_homepage_published_is = args.newHomepagePublishedIs
    this.revised_survey = args.revisedSurvey
    this.role_id = args.roleId
    this.role_name = args.roleName
    this.site_name = args.siteName
    this.sms_ideas = args.smsIdeas
    this.user_hash = args.userHash
  }
}

export default Libraries

