import Model from '@itsfadnis/jsonapi-client'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'
import isBlank from './internal/is-blank'

class Site extends Model {
  static _type = 'sites';
  static baseURL = '/site';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.name = args.name
    this.demo = args.demo
    this.blocked = args.blocked
    this.blockUsername = args.blockUsername
    this.blockPassword = args.blockPassword
    this.decommissionedAt = args.decommissionedAt
    this.primaryDomains = args.primaryDomains || []
    this.moderated = args.moderated
    this.emailSenderName = args.emailSenderName
    this.emailAddress = args.emailAddress
    this.logoUrl = args.logoUrl
    this.bannerUrl = args.bannerUrl
    this.faviconUrl = args.faviconUrl
    this.backgroundImageUrl = args.backgroundImageUrl
    this.signupFormId = args.signupFormId
    this.themeName = args.themeName
    this.state = args.state
    this.launchedAt = args.launchedAt
    this.region = args.region
    this.licenceType = args.licenceType
    this.createdAt = args.createdAt
    this.displaySiteBanner = args.displaySiteBanner
    this.displayProjectBanner = args.displayProjectBanner
    this.locale = args.locale
    this.domain = args.links && args.links.self
    this.token = args.token
    this.publishedHomePageRevisions = args.publishedHomePageRevisions
    this.bannerCaption = args.bannerCaption
  }

  _update () {
    return this.constructor.adapter
      .patch(this.constructBaseURL(), this.serialize())
      // already being deserialized by JSON API client
      .then(({ data }) => data)
      .catch(err => {
        this.errors = new Errors(err.data)
        throw err
      })
  }

  validate () {
    if (isBlank(this.name)) {
      this.errors.add({
        code: 'blank',
        source: {
          pointer: '/data/attributes/name'
        }
      })
    } else {
      const MAX_LENGTH = 255
      if (this.name.length > MAX_LENGTH) {
        this.errors.add({
          code: 'too_long',
          meta: {
            count: MAX_LENGTH
          },
          source: {
            pointer: '/data/attributes/name'
          }
        })
      }
    }
  }

  launchSite () {
    return this.constructor.adapter
      .put(this.constructBaseURL() + '/launch')
      .then(response => {
        return response.status
      })
      .catch(err => {
        this.errors = new Errors(err.data)
        throw err
      })
  }

  lock () {
    return this.constructor.adapter
      .patch(`${this.constructBaseURL()}/lock`)
      .then(({ data }) => this.constructor.deserialize(data))
      .catch((err) => {
        this.errors = new Errors(err.data)
        throw err
      })
  }

  unlock () {
    return this.constructor.adapter
      .patch(`${this.constructBaseURL()}/unlock`)
      .then(({ data }) => this.constructor.deserialize(data))
      .catch((err) => {
        this.errors = new Errors(err.data)
        throw err
      })
  }

  lockStatus () {
    return this.constructor.adapter
      .get(`${this.constructBaseURL()}/lock_status`)
      .then(({ data }) => this.constructor.deserialize(data))
      .catch((err) => {
        this.errors = new Errors(err.data)
        throw err
      })
  }
}

export default Site
