import constants from '../constants/features'
import Feature from 'api/feature'
import { setup } from 'lib/adapter'

export const fetchFeatures = () => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_FEATURES_REQUEST
  })

  try {
    if (typeof window !== 'object') {
      setup(getState().currentUser.adapter)
    }
    const features = await Feature.fetchAll()

    dispatch({
      type: constants.FETCH_FEATURES_SUCCESS,
      features
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_FEATURES_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const enableFeature = (id) => async (dispatch) => {
  dispatch({
    type: constants.ENABLE_FEATURE_REQUEST,
    id
  })

  try {
    await Feature.new({ id }).enable()

    dispatch({
      type: constants.ENABLE_FEATURE_SUCCESS,
      id
    })
  } catch (response) {
    dispatch({
      type: constants.ENABLE_FEATURE_FAILURE,
      id,
      errorMessage: response.statusText || response.status
    })
  }
}

export const disableFeature = (id) => async (dispatch) => {
  dispatch({
    type: constants.DISABLE_FEATURE_REQUEST,
    id
  })

  try {
    await Feature.new({ id }).disable()

    dispatch({
      type: constants.DISABLE_FEATURE_SUCCESS,
      id
    })
  } catch (response) {
    dispatch({
      type: constants.DISABLE_FEATURE_FAILURE,
      id,
      errorMessage: response.statusText || response.status
    })
  }
}

export const toggleFeature = (id) => {
  return {
    type: constants.TOGGLE_FEATURE,
    id
  }
}
