import isArray from './is-array'

const isBlank = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0) ||
    (isArray(value) && value.length === 0)
  )
}

export default isBlank
