import constants from 'constants/libraries';
import Intercom from 'api/intercom';

import { setup } from 'lib/adapter';

export const updateIntercom = () => async (dispatch, getState) => {
  if (typeof window !== 'object') {
    setup(getState().currentUser.adapter);
  }

  try {
    const intercom = await Intercom.query();

    dispatch({
      type: constants.FETCH_INTERCOM_DETAILS,
      intercom: { ...intercom.attributes() },
    });
  } catch (error) {
    console.log('Error in fetching intercom');
  }
};
