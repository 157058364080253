import constants from 'constants/settings'
import Settings from 'api/settings'
import isEmpty from 'lodash/isEmpty'
import { setup } from 'lib/adapter'

export const fetchSettings = (id) => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_SETTINGS_REQUEST
  })

  try {
    if (typeof window !== 'object') {
      setup(getState().currentUser.adapter)
    }

    const response = await Settings.fetchAll()
    dispatch({
      type: constants.FETCH_SETTINGS_SUCCESS,
      settings: { id: response.id, ...response.attributes() }
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_SETTINGS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const updateSettings = (key, value) => {
  return {
    type: constants.UPDATE_SETTINGS,
    key,
    value
  }
}

export const saveSettings = () => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_SETTINGS_REQUEST
  })

  const settings = new Settings(getState().siteSettings.settings)

  try {
    await settings.save()
    dispatch({
      type: constants.SAVE_SETTINGS_SUCCESS
    })
  } catch (response) {
    dispatch({
      type: constants.SAVE_SETTINGS_FAILURE,
      errorMessage: response.message || response.statusText || response.status,
      errors: settings.errors.extract()
    })
  }
}

export const validateMailchimpKeys = () => (dispatch, getState) => {
  const settingsObj = getState().siteSettings.settings
  const { mailchimpApiKey, mailchimpListId } = settingsObj

  let errors = {}
  const blanks = []
  if (isEmpty(mailchimpApiKey)) blanks.push('mailchimp-api-key')
  if (isEmpty(mailchimpListId)) blanks.push('mailchimp-list-id')

  blanks.forEach((key) => {
    errors[key] = [{
      code: 'blank',
      source: {
        pointer: `/data/attributes/${key}`
      }
    }]
  })

  dispatch({
    type: constants.UPDATE_SETTINGS_ERROR,
    errorMessage: isEmpty(errors) ? '' : 'Unprocessable Entity',
    errors
  })
}

export const validateReadspeakerLicense = () => (dispatch, getState) => {
  const settingsObj = getState().siteSettings.settings
  const readspeakerLicense = settingsObj.readspeakerLicense

  let errors = {}
  if (isEmpty(readspeakerLicense)) {
    errors['readspeaker-license'] = [{
      code: 'blank',
      source: {
        pointer: '/data/attributes/readspeaker-license'
      }
    }]
  }

  dispatch({
    type: constants.UPDATE_SETTINGS_ERROR,
    errorMessage: isEmpty(errors) ? '' : 'Unprocessable Entity',
    errors
  })
}

export const validateGovDeliveryKeys = () => (dispatch, getState) => {
  const settingsObj = getState().siteSettings.settings
  const { gdAccount, gdToken } = settingsObj

  let errors = {}
  if (isEmpty(gdAccount)) {
    errors['gdAccount'] = [{
      code: 'blank',
      source: {
        pointer: '/data/attributes/gdAccount'
      }
    }]
  } else if (/\s/.test(gdAccount)) {
    errors['gdAccount'] = [{
      code: 'no_space',
      source: {
        pointer: '/data/attributes/gdAccount'
      }
    }]
  }

  if (isEmpty(gdToken)) {
    errors['gdToken'] = [{
      code: 'blank',
      source: {
        pointer: '/data/attributes/gdToken'
      }
    }]
  } else if (!/^Basic\s/.test(gdToken)) {
    errors['gdToken'] = [{
      code: 'starts_with_basic',
      source: {
        pointer: '/data/attributes/gdToken'
      }
    }]
  }

  dispatch({
    type: constants.UPDATE_SETTINGS_ERROR,
    errorMessage: isEmpty(errors) ? '' : 'Unprocessable Entity',
    errors
  })
}

export const resetSettings = () => {
  return {
    type: constants.RESET_SETTINGS
  }
}
