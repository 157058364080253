module.exports = {
  haveTwoHoursElapsedSinceLastRequest: (req) => {
    const lastVisit = parseInt(req.cookies['unexpected_visit'], 10);
    const twoHours = 2 * 60 * 60;
    const currentTime = Math.round(new Date().getTime() / 1000);
    return currentTime > lastVisit + twoHours;
  },

  setVisitCookie: (res) => {
    const cookieValue = Math.round(new Date().getTime() / 1000);
    const maxAge = 2 * 24 * 60 * 60; // 2 days in seconds

    res.setHeader(
      'Set-Cookie',
      `unexpected_visit=${cookieValue}; Path=/; HttpOnly; Max-Age=${maxAge}; Secure; SameSite=Strict`,
    );
  },

  isAuthenticatedRequest: (req) => {
    const sessionCookie = req.cookies['_engagementhq_v3'];
    const rememberUserCookie = req.cookies['remember_user_token'];
    const tokenCookie = req.cookies['unexpected_thunder'];

    if (process.env.NODE_ENV === 'production') {
      return (
        (!!sessionCookie || (!!sessionCookie && !!rememberUserCookie)) &&
        !!tokenCookie
      );
    }

    return !!tokenCookie;
  },

  invalidateRequest: (res) => {
    const cookiesToDelete = [
      '_engagementhq_v3',
      'remember_user_token',
      'unexpected_thunder',
    ].map((cookie) => `${cookie}=; Max-Age=0; Path=/`);

    res.setHeader('Set-Cookie', cookiesToDelete);
  },
};
