'use strict'

import Model from '@itsfadnis/jsonapi-client'
import extend from './internal/extend'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'

function User () {
  var args = arguments[0] !== undefined ? arguments[0] : {}
  Model.call(this, args)
  this.id = args.id
  this.login = args.login
  this.email = args.email
  this.roleName = args.roleName
  this.roleId = args.roleId
  this.type = args.type
  this.imageUrl = args.imageUrl
  this.lastSeenAt = args.lastSeenAt
  this.createdAt = args.createdAt
  this.oldPassword = args.oldPassword
  this.newPassword = args.newPassword
  this.newPasswordConfirmation = args.newPasswordConfirmation
  this.emailOrLogin = args.emailOrLogin
  this.password = args.password
  this.rememberMe = args.rememberMe
  this.otp = args.otp
  this.provider = args.provider
  this.adminLocale = args.adminLocale
}

extend(User, Model)

User.baseURL = '/users'

User._type = 'users'

User.prototype.verifyUser = function () {
  var self = this

  return this.constructor.adapter
    .post(this.constructBaseURL() + '/verify', this.serialize())
    .then(function (response) {
      return response
    }).catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

User.prototype.verifyAdmin = function () {
  var self = this

  return this.constructor.adapter
    .get(`${this.constructBaseURL()}/admin_verification?email_or_login=${this.emailOrLogin}`)
    .then(function (response) {
      return response
    }).catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

User.prototype.resetPassword = function () {
  var self = this

  return this.constructor.adapter
    .put(
      this.constructBaseURL() + '/change_password',
      this.serialize()
    )
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

User.prototype.forgotPassword = function () {
  var self = this

  return this.constructor.adapter
    .put(
      this.constructBaseURL() + `/forgot_password_email?email=${this.email}`,
      this.serialize()
    )
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

User.prototype.resendConfirmEmail = function () {
  var self = this

  return this.constructor.adapter
    .put(
      this.constructBaseURL() + `/resend_confirmation_email?email=${this.id}`,
      this.serialize()
    )
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

User.prototype.verifyAccount = function () {
  var self = this

  return this.constructor.adapter
    .put(
      this.constructBaseURL() + `/${this.id}/confirm`,
      this.serialize()
    )
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

User.prototype.resendEmail = function () {
  var self = this

  return this.constructor.adapter
    .put(
      this.constructBaseURL() + `/${this.id}/resend_email`,
      this.serialize()
    )
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

User.prototype.updateAdminLocale = function () {
  var self = this

  return this.constructor.adapter
    .patch(`${this.constructBaseURL()}/update_locale`, this.serialize())
    .catch((err) => {
      self.errors = new Errors(err.data)
      throw err
    })
}

module.exports = User
