'use strict'
import Model from '@itsfadnis/jsonapi-client'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'
import extend from './internal/extend'

function Settings () {
  var args = arguments[0] !== undefined ? arguments[0] : {}
  Model.call(this, args)
  this.disableSignup = args.disableSignup
  this.disableFacebookSignup = args.disableFacebookSignup
  this.fbAppId = args.fbAppId
  this.fbAppSecret = args.fbAppSecret
  this.fbAppSecretSet = args.fbAppSecretSet
  this.disableTwitterSignup = args.disableTwitterSignup
  this.enableActiveDirectorySignup = args.enableActiveDirectorySignup
  this.auth0ClientId = args.auth0ClientId
  this.auth0Domain = args.auth0Domain
  this.auth0Connection = args.auth0Connection
  this.auth0RedirectUrl = args.auth0RedirectUrl
  this.ssoLoginPageMessage = args.ssoLoginPageMessage
  this.disableSocialMediaSharing = args.disableSocialMediaSharing
  this.enableTwitterHandle = args.enableTwitterHandle
  this.twitterHandle = args.twitterHandle
  this.trafficTrackingEnabled = args.trafficTrackingEnabled
  this.googleAnalyticsAccount = args.googleAnalyticsAccount
  this.customTrackingCode = args.customTrackingCode
  this.enableGoogleSignup = args.enableGoogleSignup
  this.mailchimpApiKey = args.mailchimpApiKey
  this.mailchimpListId = args.mailchimpListId
  this.readspeakerLicense = args.readspeakerLicense
  this.gdAccount = args.gdAccount
  this.gdToken = args.gdToken
  this.mailchimpApiKeyPresent = args.mailchimpApiKeyPresent
  this.mailchimpListIdPresent = args.mailchimpListIdPresent
  this.gdTokenPresent = args.gdTokenPresent
  this.gdAccountPresent = args.gdAccountPresent
  this.zoomApiKey = args.zoomApiKey
  this.zoomApiSecret = args.zoomApiSecret
  this.googleTranslationEnabled = args.googleTranslationEnabled
  this.isSocialSharingModalEnabled = args.isSocialSharingModalEnabled
  this.recaptchaSignupEnabled = args.recaptchaSignupEnabled
  this.projectDateEnabled = args.projectDateEnabled
  this.maxNumberOfAdmins = args.maxNumberOfAdmins // to track the number of allowed admins for the site based on the license
  this.customHeadCode = args.customHeadCode
}

extend(Settings, Model)

Settings.baseURL = '/settings'

Settings.prototype._update = function () {
  if (!this.fbAppSecret) {
    // Delete fbAppSecret attribute if its empty. Pass the value to the API only
    // if it has a value. This is for handling VAPT.
    delete this.fbAppSecret
  }

  var self = this

  return this.constructor.adapter
    .patch(this.constructBaseURL(), this.serialize())
    .then(function (response) {
      return self.constructor.deserialize(response.data)
    })
    .catch(function (err) {
      self.errors = new Errors(err.data)
      throw err
    })
}

Settings._type = 'settings'

module.exports = Settings
