'use strict'

import Model from '@itsfadnis/jsonapi-client'
import extend from './internal/extend'

function Feature () {
  var args = arguments[0] !== undefined ? arguments[0] : {}
  Model.call(this, args)
  this.title = args.title
  this.enabled = args.enabled
}

extend(Feature, Model)

Feature.baseURL = '/features'

Feature.prototype.enable = function () {
  return this.constructor.adapter
    .put(this.constructBaseURL() + '/' + this.id + '/enable')
}

Feature.prototype.disable = function () {
  return this.constructor.adapter
    .put(this.constructBaseURL() + '/' + this.id + '/disable')
}

Feature._type = 'features'

module.exports = Feature
